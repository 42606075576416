.ssTitre1 {
  color: $vert;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: bold;
}
.ssTitre2 {
  font-style: normal;
  font-weight: 600;
}

.desktop {
  transform: scale(0.600,0.600);
}
.smartphone {
  transform: scale(0.700,0.700);
}
.tablet {
  transform: scale(0.700,0.700);
}
.papier {
  transform: scale(0.700,0.700);
}

hr {
  &.main {
    border-style: solid;
    border-color: $vert;
    border-width: 2px;
    margin-top: 0px;
    margin-bottom: 20px;
  }
  &.tiny {
    border-style: solid;
    border-color: #CCCCCC;
    border-width: 1px;
    margin-top: 0px;
    margin-bottom: 20px;
  }
}

h1 {
  &.abo {
    text-transform: uppercase;
    color: $vert;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: bold;
    margin-top: 1rem;
  }
}