
#toTopBtn {
  position: fixed;
  bottom: 26px;
  right: 39px;
  z-index: 98;
  padding: 21px;
  background-color: #439539;
}

.js .cd-top--fade-out {
  opacity: .5
}

.js .cd-top--is-visible {
  visibility: visible;
  opacity: 1
}

.js .cd-top {
  visibility: hidden;
  opacity: 0;
  transition: opacity .3s, visibility .3s, background-color .3s
}

.cd-top {
  position: fixed;
  bottom: 20px;
  bottom: var(--cd-back-to-top-margin);
  right: 20px;
  right: var(--cd-back-to-top-margin);
  display: inline-block;
  height: 40px;
  height: var(--cd-back-to-top-size);
  width: 40px;
  width: var(--cd-back-to-top-size);
  box-shadow: 0 0 10px rgba(0, 0, 0, .05) !important;
  background: url(/img/cd-top-arrow.svg) no-repeat center 50%;
  background-color: #439539;
}