.footer {
  background: $grisClair;
  padding-top: 1rem;
  padding-bottom: 1rem;
  a {
    color: $noirTexte;
   // text-transform: uppercase;
  }
  color: $noirTexte;

  .footer-links {
    text-align: center;
    a {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      font-size: 0.8rem;
    }
  }
  .footer-copyright {
    padding-top: 0.5rem;
    font-size: 0.8rem;
    text-align: center;
  }
}