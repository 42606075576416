section.identification {
  padding-top: 3rem;
  padding-bottom: 6rem;
}

section.abonnement {

  padding-top: 4rem;
  padding-bottom: 6rem;
}

div.action {
  text-align: center;
  margin-top: 1rem;
  .btn {
    border-radius: 0;
  }
}

div.modal-body {
  h1 {
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 1rem;
  }
}

.lock-article {
  color: $gray-900;
  margin-left: 0.4rem;
  margin-top: 0.4rem;
}