@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;1,400&display=swap');

// customize some Bootstrap variables
$primary: #439539;
//@import '~@fortawesome/fontawesome-free/scss/fontawesome';
//@import '~@fortawesome/fontawesome-free/scss/regular';
//@import '~@fortawesome/fontawesome-free/scss/solid';
//@import '~@fortawesome/fontawesome-free/scss/brands';

@import "scss/variables";

// the ~ allows you to reference things in node_modules
@import "~bootstrap/scss/bootstrap";

@import "scss/home";
@import "scss/article";
@import "scss/footer";

@import "scss/header";
@import "scss/mainNav";
@import "scss/annonce";
@import "scss/login";
@import "scss/scroll";

@import "scss/abonnement";

