.section-home {
  padding-top: 2rem;
  padding-bottom: 1rem;

  h1 {
    border-bottom: 1px solid $body-color;
    margin-bottom: 1rem;
    font-size: 1.8rem;

    @include media-breakpoint-down(xs) {
      font-size: 1.3rem;
    }
    & > small {
      font-size: .8rem;
      margin-left: .5rem;
      opacity: .8;
      a {
        color: $gris;
      }

      @include media-breakpoint-down(xs) {
        text-align: right;
        display: block;
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
      }
    }
  }

  &.section-home-speciale {
    background-color: $vertClair;
  }

  &.section-home-decisions {
    background-color: $grisFonce;
  }
  &.section-home-faomag {
    background-color: $grisClair2;
    padding-bottom: 2rem;
  }
}


html, body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}
.content-wrapper {
  flex: 1 0 auto;
}
.footer {
  flex-shrink: 0;
}

section {
  &#pub,
  &#pub-home {
    background-color: #f4f4f4;
    div.container {
      background-color: white;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
    #header-pub {
      text-align: center;
    }
  }
  &#pub-home {
    background-color: white;
  }
}
#alertes-geo {
  text-align: center;
  margin-top: 2rem;
}
img#alert-geo-img {
  max-width: 300px;
}

div.explain {
  margin-bottom: 1rem;
  p {
    font-style: italic;
    font-size: 0.9rem;
    margin-bottom: 0.25rem;
    color: #787878;
  }
}