#header {
  padding-top: 1rem;
  padding-bottom: 1rem;
  img#logo {
    margin-top: 1rem;
  }

  @include media-breakpoint-down(xs) {
    padding: 0;
  }
}

#header-actions {
  text-align: right;
  padding-top: 1rem;
  padding-bottom: 1.2rem;
  font-size: 1rem;
  color: $noirTexte;
  i {
    font-size: 1rem;
    margin-right: 0.5rem;
    margin-left: 0rem;
  }
  a {
    color: $noirTexte;
    margin-left: 0.4rem;
    padding: 0.6rem;
    &#abonnement {
      background-color: $vertClair;
    }
    &#login,
    &#logout {
      padding-right: 0;
    }
  }

  @include media-breakpoint-down(xs) {

    font-size: 0.9rem;
    text-align: center;
    padding: 0.8rem 0;

    padding-right: 1rem;
    a {
      font-size: 0.9rem;
margin: 0;
      &#login,
      &#logout {
        padding-right: 0.6rem;
      }
    }
  }
}

#surnav {
  display: none;
  background-color: #1b1e21;
  color: white;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;

  #header-actions {
    text-align: right;
    padding-top: 0rem;
    font-size: 0.9rem;
    i {
      font-size: 1rem;
      margin-right: 0.5rem;
      margin-left: 2rem;
    }
    a {
      color: white;

    }
  }
}

p.header_date {
  color: $gris;
  font-size: 0.8rem;
  margin: 0.25rem 0;
  text-align: right;
}