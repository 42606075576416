.ecusson-img {
  padding-bottom: 0.2rem;
  text-align: center;
}
.ecusson-nom {
  font-size: 0.91rem;
  color: #787878;
  text-transform: capitalize;
 // padding-left: 0;
}

.annonce-content {
  border-left: 2px solid $vert;
  h2 {
    font-size: 1.3rem;
    text-transform: uppercase;
  }
  p {
    margin-bottom: 0.4rem;
    line-height: 1.3;
    &.signature {
      color: #888888;
      font-style: italic;
    }
    &.date {
      color: #787878;
      font-size: 0.8rem;
      margin-bottom: 0.25rem;
    }
  }
}
div.annonce-simap {

  border-left: 1px solid $vert;
  padding-left: 1rem;
  p.Courant-Retrait {
    padding-left: 1rem;
  }
  p {

    margin-bottom: 0.4rem;
    line-height: 1.3;

    &.Titre {
      font-weight: bold;
    }
    &.Soustitre {

      font-weight: bold;
    }
    span.i {
      font-style: italic;
      color: #787878;
    }
  }
}

div.annonce-camac {

  border-left: 1px solid $vert;
  padding-left: 1rem;
  p {

    margin-bottom: 0.4rem;
    line-height: 1.3;
    span {
      &.region {
        background-color: $vert;
        padding: 0.4rem;
        color: white;
        font-size: 0.8rem;
      }
      &.info {
        margin-top: 0.8rem;
        color: #787878;
        //font-family: "Montserrat", Times, "Times New Roman", sans-serif;
        font-weight: normal;
      }
    }
  }
}

section.content {
  background-color: #f4f4f4;
  div.container {
    background-color: white;
    h1 {
      margin-top: 2rem;
      margin-bottom: 1rem;

      @include media-breakpoint-down(xs) {
      font-size: 1.5rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
    }
  }
}

.article-home {
  padding-left: 0;
  h2 {
    font-size: 1.15rem;
  }
  p {
    margin-bottom: 0.25rem;
    &.date {
      color: #787878;
      font-size: 0.8rem;
      margin-bottom: 0.25rem;
    }
    span {
      &.cat {
        background-color: $vert;
        padding: 0.4rem;
        color: white;
        font-size: 0.8rem;
      }
    }
  }
}

section.decisions-home {
  background-color: #f4f4f4;
  padding-top: 2rem;
  padding-bottom: 2rem;

  h1 {
    border-bottom: 1px solid #787878;
    margin-bottom: 1rem;
    font-size: 1.8rem;
  }
}

.decision {
  margin-bottom: 1.5rem;
  .seance {
    color: #787878;
    font-size: 0.8rem;
    margin: 0.35rem 0;
    text-align: center;
  }
  p.cat {
    margin-top: -12px;
    text-align: center;
    margin-bottom: 0.75rem;
  }
  h2 {
    font-size: 1rem;
    text-align: center;
    a {
      color: #000;
    }
  }
}
.decision-last {
  margin-bottom: 0;
  .seance {
    margin-top: 0;
    text-align: left;
  }
  h2 {
    font-size: 0.9rem;
    text-align: left;
  }

  p.cat {
    margin-bottom: 0;
  }
  p.cat-last {
    text-align: left;
    margin-top: 0px;
    margin-bottom: 10px;
  }
}

div.article-detail {
  padding-top: 2rem;
  p {
    &.seance {
      color: #787878;
      font-size: 1rem;
      margin: 0.5rem 0;
    }
    &.date {
      color: #787878;
      font-size:0.8rem;
      margin: 0.35rem 0;

    }
    &.cat-autres {
      margin-top: 1rem;
      margin-bottom: 0.5rem;
    }
    &.autres {
      margin-bottom: 1rem;
    }
    &.cat {
      margin-bottom: 0;
    }
  }
  span {
    &.cat {
      background-color: $vert;
      padding: 0.4rem 0.6rem;
      color: white;
      font-size: 0.8rem;
     // text-transform: uppercase;

      @include media-breakpoint-down(xs) {
        padding: 0.4rem;
        font-size: 0.8rem;
      }
    }
  }
  h1 {
    font-size: 1.8rem;
    margin-top: 0.4rem !important;
    margin-bottom: 0.4rem !important;
  }
  h2, h3 {
    text-align: left;
  }
  h3 {
    font-size: 1.25rem;
  }
  h2 {
    font-size: 1.8rem;
  }
  img {
    border: 1px solid #eee;
  }
  span {
    &.cat-autres {
      background-color: $vert;
      padding: 0.8rem;
      color: white;
      font-size: 1rem;
      padding: 0.4rem;
      color: white;
      font-size: 0.8rem;
    }
  }
  div.image {
    background-color: #ffffff;
    margin-bottom: 0.8rem;
    div.legende {
      font-size: 0.8rem;
      font-style: italic;
    }
    div.credit {
      text-align: left;
       font-size: 0.8rem;
       font-style: italic;
     }
  }
}

#ariane {
  margin-top: 1rem;
  color: #787878;
  font-size: 0.9rem;
  a {
    color: #787878;
    font-size: 0.9rem;
  }

  @include media-breakpoint-down(xs) {
    display: none;
  }
}

.publicite {
  text-align: center;
  margin-top: 2rem;
}

div.mag {
  text-align: center;
  margin-top: 2rem;
  img {
    max-width: 300px;
  }
}

.epaper-archive {
  border: 1px solid #ccc;
}