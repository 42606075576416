

section#mainNav {
  background-color: $vert;

  .nav-link {
    //text-transform: uppercase;
    color: white;
    padding: 0rem 0.6rem;
    font-family: "Montserrat";
    text-decoration: none;
    font-size: 1rem;

    @include media-breakpoint-down(xs) {
      color: #000000;
      padding: 10px 0 5px 10px;
    }
  }
  .navbar {
    padding: 1rem 0;
  }
  .dropdown-menu {

    @include media-breakpoint-down(xs) {
      border: none;

      background-color: #f8f9fa !important;
    }
  }
}

.bg-light-mobile {

  @include media-breakpoint-down(xs) {
    background-color: #f8f9fa !important;
  }
}
.navbar-toggler {
  border: none;
}
a.navbar-brand {

  @include media-breakpoint-up(md) {
    display: none;
  }
  img {
    width: 165px;
  }

  margin: 0 auto;
  padding-right: 47px;
}