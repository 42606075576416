article {
  &.actualite {
    margin-bottom: 2rem;
    h2 {
      font-size: 1.2rem;
      text-align: center;
      a {
        color: $body-color;
      }
    }
    p {
      &.tag {
        margin-top: -12px;
        text-align: center;
        margin-bottom: 0.75rem;
        span {
          &.tag {
            background-color: $vert;
            color: white;
            padding: 0.4rem 0.6rem;
            font-size: 0.8rem;
          }
        }
      }
      &.date {
        color: $gris;
        font-size: 0.8rem;
        margin: 0.35rem 0;
        text-align: center;
      }
    }
    div.teaser {
      text-align: left;
      p {
        line-height: 1.4;
      }
    }
  }
  &.actualite-main {
    margin-top: 2rem;
    h1 {
      font-size: 1.5rem;
      text-align: center;
      a {
        color: $body-color;
      }
    }
    div.teaser {
      text-align: center;
    }
  }
  span {
    &.label {
      color: $gris;
    }
  }

  &.simap {
    margin-bottom: 1rem;
    border-bottom: 1px solid $gris;
    h2 {
      text-align: left;
    }

    p {
      &.tag {
        text-align: left;
        margin-top: 0px;
      }
      &.date {
        text-align: right;
      }
    }
  }
  &.trafic-routier {
      margin-bottom: 1rem;
      border-bottom: 1px solid $gris;
      h2 {
        text-align: left;
      }
      p {
        margin-bottom: 0.4rem;
        &.tag {
          text-align: left;
          margin-top: 0px;
        }
        &.date {
          text-align: right;
        }
      }
    }
  &.tribunal {

    margin-bottom: 0rem;
    border-left: 1px solid $vert;
    padding-left: 1rem;
    h2 {
      text-align: left;
    }
    p {

      &.date {
        text-align: left;
      }
      &.signature {

        color: $gris;
        font-size: 0.9rem;
        font-style: italic;
        padding-top: 0rem;
        margin-bottom: 0;
        padding-bottom: 0rem;
        border-bottom: 0px solid $grisClair;
        margin-top: 0rem;
        span.b {
          font-weight: bold;
        }
      }
      &.signature-1 {

        border-top: 1px solid $grisClair;
        margin-top: 0.5rem;
        padding-top: 0.2rem;
      }
      &.content {
        margin-bottom: 0.5rem;
        span.b {
          font-weight: bold;
        }
        span {
          &.info {
            display: block;
            color: $gris;
            margin-top: 0rem;
          }
        }
      }
    }
  }
  &.registre {
    margin-bottom: 0rem;
    border-left: 1px solid $vert;
    padding-left: 1rem;
    p {
      &.date {
        text-align: left;
      }
      &.content {
        margin-bottom: 0.3rem;
        span.b {
          font-weight: bold;
        }
        span.i {
           font-style: italic;
        }
        span {
          &.info {
            display: block;
            color: $gris;
            margin-top: 0.5rem;
          }
        }
      }
    }
  }
  &.recherche {
    p {
      &.tag {
        margin-top: 0px;
        text-align: left !important;
      }
      &.Titre {
        text-align: center;
        font-weight: bold;
        font-size: 1.5rem;
        margin-bottom: 0;
        margin-top: 0.5rem;
      }
      &.Surtitre {
        text-align: center;
        font-weight: bold;
        font-size: 1rem;
      }
      &.Courant {
        margin-bottom: 0.5rem;
        span.b {
          font-weight: bold;
        }
      }
      &.Signature {
        color: $gris;
        font-size: 0.9rem;
        font-style: italic;
        padding-top: 0rem;
        margin-bottom: 0;
        padding-bottom: 0rem;
        border-bottom: 0px solid $grisClair;
        margin-top: 0rem;
        text-align: right;
        span.b {
          font-weight: bold;
          display: block;
        }
      }
    }
    margin-top: 1rem;
  }

  &.autre {
    border-left: 1px solid $vert;
    padding-left: 1rem;
    p {
      &.tag {
        margin-top: -12px;
        text-align: center;
        margin-bottom: 0.75rem;
        span {
          &.tag {
            background-color: $vert;
            color: white;
            padding: 0.4rem 0.6rem;
            font-size: 0.8rem;
          }
        }
      }
      &.date {
        color: $gris;
        font-size: 0.8rem;
        margin: 0.35rem 0;
        text-align: left;
      }
      &.Titre {
        text-align: center;
        font-weight: bold;
        font-size: 1.5rem;
        margin-bottom: 0;
        margin-top: 0.5rem;
      }
      &.Surtitre {
         text-align: center;
         font-weight: bold;
         font-size: 1rem;
      }
      &.Courant {
        margin-bottom: 0.5rem;
        span.b {
          font-weight: bold;
        }
      }
      &.ref-annonce {
        display: none;
      }
      &.Signature {

        color: $gris;
        font-size: 0.9rem;
        font-style: italic;
        padding-top: 0rem;
        margin-bottom: 0;
        padding-bottom: 0rem;
        border-bottom: 0px solid $grisClair;
        margin-top: 0rem;
        text-align: right;
        span.b {
          font-weight: bold;
          display: block;
        }
      }
    }
  }
}

.lock-article {
  float: right;
}

p {
  &.date_edition {
    color: $gris;
    font-size: 0.8rem;
    margin: 0rem 0 0.5rem 0 !important;
    text-align: left;
    padding-top: 0.5rem;
  }
}

div#encadre_article {
  border: 2px solid $vert;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
}